import React, { Component } from "react";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";
import { NavLink } from 'react-router-dom';
import { navigateTo } from '../../../../actions/utility.action';
import intl from 'react-intl-universal';
import logos from '../../../../assets/logos/google-pay.svg';

class SpendGooglePay extends Component {
    render() {
		if (window.location.hash === '#google-pay') {
			const interval = setInterval(() => {
				const el = document.getElementsByClassName('section-google-pay')[0];
				if (el) {
					window.scrollTo({
						left: 0,
						top: el.parentElement.offsetTop,
						behavior: 'smooth',
					});
					clearInterval(interval);
				}
			}, 100);
		}

        return (
            <LeftRight
	            config={`green right section-google-pay`}
                left={
                    <div className="placeholder"></div>
                }
                right={
                    <div className="wrapper">
	                    <img src={logos} alt="" loading="lazy" style={{
												width: '158px',
		                    marginBottom: '23px',
											}}/>
                        <h2>
	                        { intl.getHTML('page.Spend.GooglePay.h') }
                        </h2>
                        <p>
	                        { intl.getHTML('page.Spend.GooglePay.p') }
                        </p>
	                    <button className="btn-primary inverted">
		                    <NavLink
			                    onClick={() => navigateTo()}
			                    to="/google-pay"
		                    >
			                    { intl.getHTML('page.Spend.GooglePay.cta') }
		                    </NavLink>
	                    </button>
                    </div>
                }
            />
        );
    }
}

export default SpendGooglePay;
