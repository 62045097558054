import React, { Component } from 'react';

import '../styles/pages/spend.scss';

// Components
import Navbar from '../components/navbar/navbar';
import NavbarMobile from '../components/navbar/navbarMobile';
import Header from '../components/blocks/contentContainers/spend/spendHeader.component';
import Footer from '../components/footer';

import SpendNo from '../components/blocks/contentContainers/spend/spendNo.component';
import SpendFree from '../components/blocks/contentContainers/spend/spendFree.component';
import SpendGooglePay from '../components/blocks/contentContainers/spend/spendGooglePay.component';
import SpendApplePay from '../components/blocks/contentContainers/spend/spendApplePay.component';

class Spend extends Component {
  render() {
    return (
        <div className="container" style={{ justifyContent: 'space-evenly' }}>
          <Navbar/>
          <NavbarMobile/>
          <Header/>
          <SpendGooglePay/>
          <SpendApplePay/>
          <SpendFree/>
          <SpendNo/>
          <Footer/>
        </div>
    );
  }
}

export default Spend;
