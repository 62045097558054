import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';

// Actions
import { navigateTo } from "../actions/utility.action";

// Style
import "../styles/components/footer.scss";

// Assets
import { socials } from "../assets/files/socials";
import logo from "../assets/logos/MJ-icon-grn.svg";
import apple from "../assets/icons/apple-store.svg";
import playstore from "../assets/icons/play-store.svg";
import flagIE from "../assets/images/flags/ie.svg";
import flagES from "../assets/images/flags/es.svg";
import appleES from '../assets/icons/apple-storeES.svg';
import playstoreES from '../assets/icons/play-storeES.svg';

class Footer extends Component {

    currentLocale = intl.getInitOptions().currentLocale

    render() {
        let renderSocials = socials.map((value, index) => (
            <li
                key={`social_link_${value.name}_${index}`}
                className="social-item"
            >
                <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                >
                    <DgPicture>
                        <img className="social-img" src={value.icon} alt={value.name}/>
                    </DgPicture>
                    <DgPicture>
                        <img src={value.iconAlt} className="social-img-alt" alt={value.name} loading="lazy"/>
                    </DgPicture>
                </a>
            </li>
        ));

        return (
            <footer className="footer-container">
                <div className="footer">
                    <div className="logo-container">
                        <img className="logo" src={logo} alt=""/>
                    </div>
                    <div className="app-info-container">
                        <div className="title">
                            {intl.get('component.Footer.download')}
                        </div>
                        <a
                            href={'https://apps.apple.com/ie/app/money-jar/id1469836079'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                        >
                            <DgPicture>
                                <img style={{ height: '38px' }} src={this.currentLocale === 'es' ? appleES : apple} alt="App Store" loading="lazy"/>
                            </DgPicture>
                        </a>
                        <a
                            href={'https://play.google.com/store/apps/details?id=com.mymoneyjar.mobile&hl=en_IE&gl=IE'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                        >
                            <DgPicture>
                                <img style={{ height: '38px' }} src={this.currentLocale === 'es' ? playstoreES : playstore} alt="Google Play" loading="lazy"/>
                            </DgPicture>
                        </a>
                    </div>

                    <div className="socials">
                        <ul className="social-list">{renderSocials}</ul>
                    </div>

                    <ul className="quicklinks">
                        <li>
                            <Link
                                to={`/apple-pay`}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.applePay')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'/contact'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.support')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'/contact'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.contact')}
                            </Link>
                        </li>
                    </ul>

                    <section className="legal">
                        <h2>{intl.get('component.Footer.h')}</h2>
                        <p>{intl.get('component.Footer.p')}</p>

                        <div className="terms">
                            <div>
                                <span>{intl.get('market.ie')}</span>
                                <img src={flagIE} alt=""/>
                            </div>
                            <Link
                                to={`/terms-and-conditions`}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.terms')}
                            </Link>
                            <strong className="desktop-only">|</strong>
                            <Link
                                to={'/privacy-policy'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.privacy')}
                            </Link>
                        </div>
                        <div className="legal-note">
                            {intl.get('component.Footer.legalIE')}
                        </div>

                        <div className="terms">
                            <div>
                                <span>{intl.get('market.es')}</span>
                                <img src={flagES} alt=""/>
                            </div>
                            <Link
                                to={`/terms-and-conditions?lang=es&market=es`}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.terms')}
                            </Link>
                            <strong className="desktop-only">|</strong>
                            <Link
                                to={'/privacy-policy?lang=es&market=es'}
                                className="btn-link"
                                onClick={() => navigateTo()}
                            >
                                {intl.get('component.Footer.privacy')}
                            </Link>
                        </div>
                        <div className="legal-note">
                            {intl.get('component.Footer.legalES')}
                        </div>
                    </section>

                </div>
            </footer>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Footer);
