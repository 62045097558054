import React, { Component } from "react";
import AOS from "aos";

//Styles
import "../../../../styles/components/applePay/applePayHow.scss";

//Assets
import { initAnimations } from "../../../../assets/files/animations.js";
import intl from 'react-intl-universal';

class ApplePayHow extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }

    render() {
        return (
                <div className="applePay-how-container">
                    <div className="applePay-how-heading">
                        <h1>{ intl.getHTML('page.ApplePay.How.h1') }</h1>
                    </div>
                    <div className="applePay-how-row">

                        <div className="applePay-how-col">
                            <h2>{ intl.getHTML('page.ApplePay.How.h2') }</h2>
                            <iframe
                                className="applePay-how-vid"
                                src={intl.get('page.ApplePay.How.video1')}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                        </div>

                        <div className="applePay-how-col">
                            <h2>{ intl.getHTML('page.ApplePay.How.h3') }</h2>
                            <iframe
                                className="applePay-how-vid"
                                src={intl.get('page.ApplePay.How.video2')}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                        </div>

                        <div className="applePay-how-col">
                            <h2>{ intl.getHTML('page.ApplePay.How.h4') }</h2>
                            <iframe
                                className="applePay-how-vid"
                                src={intl.get('page.ApplePay.How.video3')}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                        </div>

                    </div>
                </div>
        );
    }
}

export default ApplePayHow;
