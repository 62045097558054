import React, { Component } from "react";

// Components
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Header from "../components/blocks/contentContainers/home/homeHeader.component";
import Footer from "../components/footer";
import HomeShare from "../components/blocks/contentContainers/home/homeShare.component";
import HomeSetup from "../components/blocks/contentContainers/home/homeSetup.component";
import HomeFree from "../components/blocks/contentContainers/home/homeFree.component";
import HomeSecure from "../components/blocks/contentContainers/home/homeSecure.component";
import SpendApplePay from '../components/blocks/contentContainers/spend/spendApplePay.component';
import HomeGooglePay from '../components/blocks/contentContainers/home/homeMobilePayments.component';

class Home extends Component {
    render() {
        return (
            <div
                className="container"
                style={{ justifyContent: "space-evenly" }}
            >
                <Navbar />
                <NavbarMobile />
                <Header />
                <HomeShare data-aos="fade-in" />
                <HomeSetup />
                <HomeFree />
                <SpendApplePay/>
                <HomeGooglePay />
                <HomeSecure />
                <Footer />
            </div>
        );
    }
}

export default Home;
