import React, { Component } from "react";
import AOS from "aos";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';
import LeftRightMiddle from "../../../components/blocks/leftRight/leftRightMiddle.js";
import background from "../../../assets/images/setup-hero-1.jpg";
import backgroundMobile from "../../../assets/images/setup-hero-1-mobile.jpg";
import { initAnimations } from "../../../assets/files/animations.js";

class SetupHeader extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }
    
    render() {
	    const headerContent = (
		    <div className="heading wrapper">
			    <h1 className="max-width">
				    { intl.getHTML('page.Setup.Header.h1') }
			    </h1>
			    <h2>{ intl.getHTML('page.Setup.Header.h2') }</h2>
			    <ul>
				    <li className="b1" data-aos="fade-left" data-aos-delay="600">
					    <p>{ intl.getHTML('page.Setup.Header.li1') }</p>
				    </li>
				    <li className="b2" data-aos="fade-left" data-aos-delay="1000">
					    <p>{ intl.getHTML('page.Setup.Header.li2') }</p>
				    </li>
				    <li className="b3" data-aos="fade-left" data-aos-delay="1400">
					    <p>{ intl.getHTML('page.Setup.Header.li3') }</p>
				    </li>
			    </ul>
			    <p>
				    <span data-aos="fade-left" data-aos-delay="1800">{ intl.getHTML('page.Setup.Header.p1') }</span>
				    {" "}
				    <span data-aos="fade-left" data-aos-delay="2200">{ intl.getHTML('page.Setup.Header.p2') }</span>
				    {" "}
				    <span data-aos="fade-left" data-aos-delay="2600">{ intl.getHTML('page.Setup.Header.p3') }</span>
			    </p>
		    </div>
	    );

	    return (
            <LeftRightMiddle
                customLeftRight={"LeftRightMiddle-container header"}
                left={headerContent}
                middle={<div />}
                right={<div />}
                bgImage={
                    <DgPicture className="image-container">
                        <img src={background} alt="" loading="lazy" />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container contain">
                        <img src={backgroundMobile} alt="" loading="lazy" />
                    </DgPicture>
                }
                hideOverlay={true}
                config={`right-background`}
            />
        );
    }
}

export default SetupHeader;
