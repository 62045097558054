import React, { Component } from "react";
import { connect } from "react-redux";
import { DgPicture } from "dg-utils";
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';

// Assets
import leftImage from "../../../../assets/images/pricing-3.png";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";

//Actions
import { navigateTo } from "../../../../actions/utility.action";

class HomeFree extends Component {
    render() {
        return (
            <LeftRight
                left={
                    <div className="wrapper">
                        <DgPicture>
                            <img
                                src={leftImage}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                right={
                    <div className="wrapper">
                        <h2>{ intl.get('page.Home.Free.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Home.Free.p1') }
                        </p>
                        <p>
	                        <small>{ intl.get('page.Home.Free.p2') }</small>
                        </p>
                        <button className="btn-primary inverted">
                            <Link onClick={() => navigateTo()} to="/setup">{ intl.get('page.Home.Free.cta') }</Link>
                        </button>
                    </div>
                }
                config={`green right`}
            />
        );
    }
}

export default connect(null, { navigateTo })(HomeFree);
