import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';

import '../../../../styles/components/home/mobilePaymentsLogos.scss';

import sideImg from '../../../../assets/images/home-mobilePayments.jpg';
import logos from '../../../../assets/logos/google-pay.svg';
import { navigateTo, setIsMobileSite } from '../../../../actions/utility.action';
import LeftRight from '../../leftRight/leftRight';

class HomeGooglePay extends Component {
  render() {
    return (
        <LeftRight
            right={
              <div className="wrapper">
                <img src={logos} alt="" loading="lazy" className={'logos'}/>
                <h2>
                  {intl.get('page.Home.GooglePay.h')}
                </h2>
                <p className="semi-bold">
                  {intl.get('page.Home.GooglePay.p1')}
                </p>
                <button className="btn-primary inverted">
                  <NavLink
                      onClick={() => navigateTo()}
                      to="/google-pay"
                  >
                    {intl.get('page.Home.GooglePay.cta')}
                  </NavLink>
                </button>
              </div>
            }
            left={
              <div>
                <img
                    src={sideImg}
                    alt=""
                    loading="lazy"
                />
              </div>
            }
            config={`right home-google-pay`}
        />
    );
  }
}

const mapStateToProps = (state) => ({
  isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {
  setIsMobileSite,
})(HomeGooglePay);
