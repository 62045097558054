import React, { Component } from "react";
import AOS from "aos";
import { DgPicture } from "dg-utils";
//Styles
import "../../../../styles/components/applePay/applePaySafe.scss";

//Assets
import {ReactComponent as Contactless} from "../../../../assets/images/applePay-contactless.svg";
import {ReactComponent as AppleWallet} from "../../../../assets/images/appleWallet.svg";
import { initAnimations } from "../../../../assets/files/animations.js";
import intl from 'react-intl-universal';

class ApplePaySafe extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }

    render() {
        return (
            <div className="clamp">
            <div className="applePay-safe-container">
                <div className="applePay-safe-row">
                    <div className="applePay-safe-col">
                        <h2>{ intl.getHTML('page.ApplePay.Safe.h1') }</h2>
                        <p>
	                        { intl.getHTML('page.ApplePay.Safe.p1') }
                        </p>
                    </div>
                    <div className="applePay-safe-col">
                        <h2>{ intl.getHTML('page.ApplePay.Safe.h2') }</h2>
                        <p>
	                        { intl.getHTML('page.ApplePay.Safe.p2') }
                        </p>
                    </div>
                    <div className="applePay-safe-col">
                        <h2>{ intl.getHTML('page.ApplePay.Safe.h3') }</h2>
                        <p>
	                        { intl.getHTML('page.ApplePay.Safe.p3') }
                        </p>
                        <DgPicture>
                            <Contactless className="contactless" alt="" />
                        </DgPicture>
                    </div>
                </div>
                <div className="applePay-safe-bottom-row">
                    <h2>{ intl.getHTML('page.ApplePay.Safe.h4') }</h2>
                    <p>
	                    { intl.getHTML('page.ApplePay.Safe.p4') }
                    </p>

                    <a className="appleWallet" href="shoebox://payment_setup">
                        <AppleWallet alt=""/>
                    </a>
                    <p>
	                    { intl.getHTML('page.ApplePay.Safe.p5') }
                    </p>

                    <p>
	                    { intl.getHTML('page.ApplePay.Safe.p6') }
	                    {" "}
                        <a
                            className="support-link"
                            href="https://support.apple.com/en-gb/HT208531"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            support.apple.com/en-gb/km207105
                        </a>
                    </p>
                </div>
                    </div>
                        </div>
        );
    }
}

export default ApplePaySafe;
