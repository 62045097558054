import React, { Component } from "react";
import { connect } from "react-redux";
import { DgPicture } from "dg-utils";

// Styles
import "../../styles/components/landing/content.scss";

// Assets
import BG1 from "../../assets/images/landing/bg-01.png";
import BG2 from "../../assets/images/landing/bg-02.png";
import BG3 from "../../assets/images/landing/bg-03.png";
import BG4 from "../../assets/images/landing/bg-04.png";
import BG5 from "../../assets/images/landing/bg-05.png";
import BG6 from "../../assets/images/landing/bg-06.png";

import playstore from "../../assets/icons/play-store.svg";
import apple from "../../assets/icons/apple-store.svg";
import playstoreES from "../../assets/icons/play-storeES.svg";
import appleES from "../../assets/icons/apple-storeES.svg";

// Components
import PhoneDisplay from "./phone.js";
import intl from 'react-intl-universal';

class LandingContent extends Component {

    currentLocale = intl.getInitOptions().currentLocale

    render() {
        return (
            <div className="container">
                <div className="landing-container">
                    <ul className="landing-background-container">
                        <li className={ this.props.slide === 1 ? "landing-background-item active" : "landing-background-item" }>
                            <DgPicture>
                                <img className="landing-background-item-img" src={BG1} alt="" />
                            </DgPicture>
                        </li>
                        <li className={ this.props.slide === 2 ? "landing-background-item active" : "landing-background-item" }>
                            <DgPicture>
                                <img className="landing-background-item-img" src={BG2} alt="" />
                            </DgPicture>
                        </li>
                        <li className={ this.props.slide === 3 ? "landing-background-item active" : "landing-background-item" }>
                            <DgPicture>
                                <img className="landing-background-item-img" src={BG3} alt="" />
                            </DgPicture>
                        </li>
                        <li className={ this.props.slide === 4 ? "landing-background-item active" : "landing-background-item" }>
                            <DgPicture>
                                <img className="landing-background-item-img" src={BG5} alt="" />
                            </DgPicture>
                        </li>
                        <li className={ this.props.slide === 5 ? "landing-background-item mobile-alt active" : "landing-background-item" }>
                            <DgPicture>
                                <img className="landing-background-item-img" src={BG5} alt="" />
                            </DgPicture>
                        </li>
                        <li className={ this.props.slide === 6 ? "landing-background-item active" : "landing-background-item" }>
                            <DgPicture>
                                <img className="landing-background-item-img" src={BG6} alt="" />
                            </DgPicture>
                        </li>
                    </ul>

	                <div className={ `phone-display ${ this.props.slide === 5 ? "alt-slide" : "" }` }>
		                <PhoneDisplay phoneSlide={this.props.slide} />
	                </div>

                    <div className="landing-background-body-content">
                        <div className="landing-background-body-content-main">
                            <ul className="landing-background-body-content-main-container">
                                <li className={ this.props.slide === 1 ? `landing-background-body-content-main-item active` : `landing-background-body-content-main-item` }>
                                    <h2>
	                                    { intl.getHTML('page.Download.li1') }
                                    </h2>
                                </li>
                                <li className={ this.props.slide === 2 ? `landing-background-body-content-main-item active` : `landing-background-body-content-main-item` }>
                                    <h2>
	                                    { intl.getHTML('page.Download.li2') }
                                    </h2>
                                </li>
                                <li className={ this.props.slide === 3 ? `landing-background-body-content-main-item active` : `landing-background-body-content-main-item` }>
                                    <h2>
	                                    { intl.getHTML('page.Download.li3') }
                                    </h2>
                                </li>
                                <li className={ this.props.slide === 4 ? `landing-background-body-content-main-item active` : `landing-background-body-content-main-item` }>
                                    <h2>
	                                    { intl.getHTML('page.Download.li5') }
                                    </h2>
                                </li>
                                <li className={ this.props.slide === 5 ? `landing-background-body-content-main-item active` : `landing-background-body-content-main-item` }>
                                    <h2>
	                                    { intl.getHTML('page.Download.li5') }
                                    </h2>
                                </li>
                                <li className={ this.props.slide === 6 ? `landing-background-body-content-main-item active` : `landing-background-body-content-main-item` }>
                                    <h2>
	                                    { intl.getHTML('page.Download.li6') }
                                    </h2>
                                </li>
                            </ul>
                        </div>
                        <div className="landing-background-body-content-buttons">
                            <a
                                href={"https://apps.apple.com/ie/app/money-jar/id1469836079"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="app-link apple"
                            >
                                <DgPicture>
                                    <img className="app-img" src={this.currentLocale === 'es' ? appleES : apple} alt="App Store" />
                                </DgPicture>
                            </a>
                            <a
                                href={"https://play.google.com/store/apps/details?id=com.mymoneyjar.mobile&hl=en_IE&gl=IE"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="app-link"
                            >
                                <DgPicture>
                                    <img className="app-img" src={this.currentLocale === 'es' ? playstoreES : playstore} alt="Google Play" />
                                </DgPicture>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobileSite: state.utility.isMobileSite,
    };
};

export default connect(mapStateToProps, {})(LandingContent);
