import React, { Component } from 'react';
import intl from 'react-intl-universal';

import '../styles/pages/googlePay.scss';

//Assets
import nfc from '../assets/images/nfc.png';
import gpay from '../assets/images/gpay/logo.png';
import g1 from '../assets/images/gpay/1.png';
import g2 from '../assets/images/gpay/2.png';
import g3 from '../assets/images/gpay/3.png';
import g4 from '../assets/images/gpay/4.png';

// Components
import Navbar from '../components/navbar/navbar';
import NavbarMobile from '../components/navbar/navbarMobile';
import Footer from '../components/footer';
import LeftRightMiddle from '../components/blocks/leftRight/leftRightMiddle';
import { DgPicture } from 'dg-utils';
import logos from '../assets/logos/google-pay.svg';

class GooglePay extends Component {
  render() {
    return (
        <div
            className="container page-google-pay"
            style={{ justifyContent: 'space-evenly' }}
        >
          <Navbar/>
          <NavbarMobile/>

          <LeftRightMiddle
              customLeftRight={'LeftRightMiddle-container header'}
              left={
                <div className="heading wrapper">
                  <h1 style={{
                    paddingBottom: '37px',
                  }}>
                    {intl.getHTML('page.GooglePay.section1.h')}
                  </h1>
                  <DgPicture>
                    <img src={logos} loading="lazy" alt="" style={{
                      width: '128px',
                    }}/>
                  </DgPicture>
                </div>
              }
              middle={<div/>}
              right={<div/>}
              hideOverlay={true}
              config={`right-background section-top`}
          />

          <aside className="sect col-3">
            <div>
              <h2>{intl.getHTML('page.GooglePay.section2.h1')}</h2>
              <p>
                {intl.getHTML('page.GooglePay.section2.p1')}
              </p>
            </div>
            <div>
              <h2>{intl.getHTML('page.GooglePay.section2.h2')}</h2>
              <p>
                {intl.getHTML('page.GooglePay.section2.p2')}
              </p>
            </div>
            <div>
              <h2>{intl.getHTML('page.GooglePay.section2.h3')}</h2>
              <p>
                {intl.getHTML('page.GooglePay.section2.p3')}
              </p>
              <img src={nfc} loading="lazy" alt=""/>
              <img src={gpay} loading="lazy" alt=""/>
            </div>
          </aside>

          <aside className="sect get-started">
            <h2>{intl.getHTML('page.GooglePay.section3.h')}</h2>
            <p>{intl.getHTML('page.GooglePay.section3.p')}</p>
            <ol>
              <li>
                <header>
                  <h3>1.</h3>
                  <p>
                    {intl.getHTML('page.GooglePay.section3.li1')}
                  </p>
                </header>
                <img src={g1} loading="lazy" alt=""/>
              </li>
              <li>
                <header>
                  <h3>2.</h3>
                  <p>
                    {intl.getHTML('page.GooglePay.section3.li2')}
                  </p>
                </header>
                <img src={g2} loading="lazy" alt=""/>
              </li>
              <li>
                <header>
                  <h3>3.</h3>
                  <p>
                    {intl.getHTML('page.GooglePay.section3.li3')}
                  </p>
                </header>
                <img src={g3} loading="lazy" alt=""/>
              </li>
              <li>
                <header>
                  <h3>4.</h3>
                  <p>
                    {intl.getHTML('page.GooglePay.section3.li4')}
                  </p>
                </header>
                <img src={g4} loading="lazy" alt=""/>
              </li>
            </ol>
          </aside>

          <Footer/>
        </div>
    );
  }
}

export default GooglePay;
