import React, { Component } from "react";

// Components
import Navbar from "../components/navbar/navbar";
import NavbarMobile from "../components/navbar/navbarMobile";
import Footer from "../components/footer";
import ApplePayHeader from "../components/blocks/contentContainers/applePay/applePayHeader.component";
import ApplePaySafe from "../components/blocks/contentContainers/applePay/applePaySafe.component";
import ApplePayHow from "../components/blocks/contentContainers/applePay/appPayHow.component";

class ApplePay extends Component {
    render() {
        return (
            <div className="container" style={{ justifyContent: "space-evenly" }}>
                <Navbar />
                <NavbarMobile />
                <ApplePayHeader />
                <ApplePaySafe />
                <ApplePayHow />
                <Footer />
            </div>
        );
    }
}

export default ApplePay;
