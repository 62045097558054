import React, { Component } from "react";
import AOS from "aos";
import { DgPicture } from "dg-utils";

//Component
import LeftRightMiddle from "../../../blocks/leftRight/leftRightMiddle.js";

//Assets
import {ReactComponent as HeaderLogo} from "../../../../assets/images/applePay-header.svg";
import background from "../../../../assets/images/applePay-hero-2.png";
import backgroundMobile from "../../../../assets/images/applePay-hero-2-mobile.png";
import { initAnimations } from "../../../../assets/files/animations.js";
import intl from 'react-intl-universal';

class ApplePayHeader extends Component {
    componentDidMount() {
        AOS.init(initAnimations);
    }

    render() {
	    const headerContent = (
		    <div className="heading wrapper">
			    <h1>
				    { intl.getHTML('page.ApplePay.Header.h') }
			    </h1>
			    <DgPicture>
				    <HeaderLogo alt="Apple Pay" />
			    </DgPicture>
		    </div>
	    );

	    return (
            <LeftRightMiddle
                customLeftRight={"LeftRightMiddle-container header"}
                left={headerContent}
                middle={<div />}
                right={<div />}
                bgImage={
                    <DgPicture className="image-container dont-cover">
                        <img
                            src={background}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                bgImageMobile={
                    <DgPicture className="image-container">
                        <source
                            className="applePay-safe-source"
                            srcset={backgroundMobile}
                            media="(min-width:900px)"
                            alt=""
                        />
                        <img
                            className="applePay-safe-source"
                            src={backgroundMobile}
                            alt=""
                            loading="lazy"
                        />
                    </DgPicture>
                }
                hideOverlay={true}
                config={`right-background`}
            />
        );
    }
}

export default ApplePayHeader;
