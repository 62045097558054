import React, { Component } from "react";
import { DgPicture } from "dg-utils";
import intl from 'react-intl-universal';
import intro from "../../../assets/images/numberous-freefor-1.png";
import LeftRight from '../../../components/blocks/leftRight/leftRight';

class SetupFast extends Component {
    render() {
        return (
            <LeftRight
                left={
                    <div className="wrapper">
                        <DgPicture>
                            <img src={intro} className="small-img" alt="" loading="lazy"/>
                        </DgPicture>
                    </div>
                }
                right={
                    <div className="wrapper">
                        <h2>{ intl.getHTML('page.Setup.Fast.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Setup.Fast.p1') }
                        </p>
                        <p>
	                        { intl.getHTML('page.Setup.Fast.p2') }
                        </p>
                    </div>
                }
                config={`green right`}
            />
        );
    }
}

export default SetupFast;
