import React from "react";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import intl from 'react-intl-universal';
import { store } from "./store";
import history from "./history";

// Styles
import "dg-cookies/dist/index.css";
import "./styles/App.scss";
import "aos/dist/aos.css";

// Pages
import Home from "./pages/home";
import Setup from "./pages/setup/setup";
import About from "./pages/about";
import Plan from "./pages/plan";
import Spend from "./pages/spend";
import ApplePay from "./pages/applePay";
import GooglePay from "./pages/googlePay";
import Contact from "./pages/contact";
import Policy from "./pages/policy";
import Terms from './pages/termsAndConditions';
import GetApp1 from "./pages/getapp1";
import Download from "./pages/download";

const localeList = [
	{
		label: "English",
		value: "en",
	},
	{
		label: "Español",
		value: "es",
	},
];

const locales = Object.fromEntries(localeList.map(item => [item.value, require(`./i18n/${item.value}.json`)]))

function App() {
	const [isInitDone, setInitDone] = React.useState(false);

	React.useEffect(() => {
		initializeIntl();
	});

	const initializeIntl = async () => {
		const url = new URL(document.location.toString());
		const langFromUrl = url.searchParams.get('lang');
		const intlLocale  = intl.determineLocale({
			urlLocaleKey: 'lang',
			localStorageLocaleKey: 'lang',
			fallbackLocale: 'en',
			escapeHtml: false,
		}).split('-')[0];
		console.log('intl locale', intlLocale);
		let currentLocale = langFromUrl ?? sessionStorage.getItem("currentLocale") ?? intlLocale;
		console.log('determined locale', currentLocale);

		// fallback to "en" if the currentLocale isn't on localeList
		if (!localeList.some(item => item.value === currentLocale)) {
			currentLocale = "en";
		}
		console.log('currentLocale', currentLocale);

		await setCurrentLocale(currentLocale);
		setInitDone(true);
	}

	// call on init and on locale change
	const setCurrentLocale = async (currentLocale) => {
		sessionStorage.setItem("currentLocale", currentLocale);
		await intl.init({
			currentLocale,
			locales,
		});
	};

	return !isInitDone ? '' : (
			<Provider store={store}>
				<Router history={history}>
					<Switch>
						<Route path="/" exact component={Home} />
						<Route path="/setup" exact component={Setup} />
						<Route path="/about" exact component={About} />
						<Route path="/plan" exact component={Plan} />
						<Route path="/spend" exact component={Spend} />
						<Route path="/contact" exact component={Contact} />
						<Route path="/privacy-policy" exact component={Policy} />
						<Route path="/privacy-policy-may" exact component={Policy} />
						<Route path="/terms-and-conditions" exact component={Terms} />
						<Route path="/terms-and-conditions-may" exact component={Terms} />
						<Route path="/apple-pay" exact component={ApplePay} />
						<Route path="/google-pay" exact component={GooglePay} />
						<Route path="/getapp" exact component={GetApp1} />
						<Route path="/download" exact component={Download} />
					</Switch>
				</Router>
			</Provider>
	);
}

export default App;
