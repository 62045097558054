import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './pricePlans.scss';
import h from '../../../assets/images/setup/h.png';
import lite from '../../../assets/images/setup/lite.png';
import plus from '../../../assets/images/setup/plus.png';
import active from '../../../assets/images/setup/active.png';

class PricePlans extends Component {
  render() {
    return (
        <div className="price-plans">
          <a name="price-plans"></a>
          <section>
            <header>
              <article>
                <h2>{intl.getHTML('page.Setup.PricePlans.h1')}</h2>
                <h3>{intl.getHTML('page.Setup.PricePlans.h2')}</h3>
                <p>{intl.getHTML('page.Setup.PricePlans.p')}</p>
              </article>
              <img src={h} alt="" loading="lazy"/>
            </header>

            <table>
              <thead>
              <tr className="plan-name">
                <td className="hide-mobile"></td>
                <td>
                  <div>
                    <img src={lite} alt="" loading="lazy"/>
                    {intl.getHTML('page.Setup.PricePlans.lite')}
                  </div>
                </td>
                <td>
                  <div>
                    <img src={plus} alt="" loading="lazy"/>
                    {intl.getHTML('page.Setup.PricePlans.plus')}
                  </div>
                </td>
                <td>
                  <div>
                    <img src={active} alt="" loading="lazy"/>
                    {intl.getHTML('page.Setup.PricePlans.active')}
                  </div>
                </td>
              </tr>
              <tr className="plan-price">
                <td className="hide-mobile"></td>
                <td>
                  <div>
                    <h4><strike>€2.99</strike>/{intl.getHTML('page.Setup.PricePlans.month')}</h4>
                    {intl.getHTML('page.Setup.PricePlans.firstMonthFree')}
                  </div>
                </td>
                <td>
                  <div>
                    <h4>€5.99/{intl.getHTML('page.Setup.PricePlans.month')}</h4>
                    {intl.getHTML('page.Setup.PricePlans.saveUpTo')} €5.23
                  </div>
                </td>
                <td>
                  <div>
                    <h4>€9.99/{intl.getHTML('page.Setup.PricePlans.month')}</h4>
                    {intl.getHTML('page.Setup.PricePlans.saveUpTo')} €6.96
                  </div>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr className="hide-mobile">
                <td colSpan="4">&nbsp;</td>
              </tr>
              <tr>
                <td colSpan="3">
                  <h4>{intl.getHTML('page.Setup.PricePlans.freeH')}</h4>
                </td>
                <td className="hide-mobile"></td>
              </tr>
              <tr className="hide-desktop">
                <td colSpan="3" className="normal-font-weight">
                  {intl.getHTML('page.Setup.PricePlans.payments')}
                </td>
              </tr>
              <tr>
                <td className="normal-font-weight hide-mobile">
                  {intl.getHTML('page.Setup.PricePlans.payments')}
                </td>
                <td>
                  12/{intl.getHTML('page.Setup.PricePlans.mo')}
                </td>
                <td>
                  25/{intl.getHTML('page.Setup.PricePlans.mo')}
                </td>
                <td>
                  40/{intl.getHTML('page.Setup.PricePlans.mo')}
                </td>
              </tr>
              <tr className="hide-desktop">
                <td colSpan="3" className="normal-font-weight">
                  {intl.getHTML('page.Setup.PricePlans.atm')}
                </td>
              </tr>
              <tr>
                <td className="normal-font-weight hide-mobile">
                  {intl.getHTML('page.Setup.PricePlans.atm')}
                </td>
                <td>
                  1/{intl.getHTML('page.Setup.PricePlans.mo')}
                </td>
                <td>
                  2/{intl.getHTML('page.Setup.PricePlans.mo')}
                </td>
                <td>
                  4/{intl.getHTML('page.Setup.PricePlans.mo')}
                </td>
              </tr>
              <tr className="hide-desktop">
                <td colSpan="3">
                  {intl.getHTML('page.Setup.PricePlans.ppu')}
                </td>
              </tr>
              <tr>
                <td className="hide-mobile">
                  <h4>{intl.getHTML('page.Setup.PricePlans.ppu')}</h4>
                </td>
                <td><span className="checkmark">&#x2713;</span></td>
                <td><span className="checkmark">&#x2713;</span></td>
                <td><span className="checkmark">&#x2713;</span></td>
              </tr>
              <tr className="hide-mobile">
                <td colSpan="4">&nbsp;</td>
              </tr>
              </tbody>
            </table>

            <p>{intl.getHTML('page.Setup.PricePlans.f1')}</p>
            <p>{intl.getHTML('page.Setup.PricePlans.f2')}</p>

          </section>
        </div>
    );
  }
}

export default PricePlans;
