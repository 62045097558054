import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "../../components/navbar/navbar";
import NavbarMobile from "../../components/navbar/navbarMobile";
import Footer from "../../components/footer";
import SetupHeader from './components/setupHeader';
import SetupFastComponent from './components/setupFast';
import PricePlans from './components/pricePlans';

class Setup extends Component {
    render() {
        return (
            <div
                className="container"
                style={{ justifyContent: "space-evenly" }}
            >
                <Navbar />
                <NavbarMobile />
                <SetupHeader />
                <SetupFastComponent />
                <PricePlans />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobileSite: state.utility.isMobileSite,
});

export default connect(mapStateToProps, {})(Setup);
